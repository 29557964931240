<template>
  <div class="add-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加会议</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="left">
        <div class="preview-panel" :style="'background-image: url(' + addForm.bg_img + ')'">
          <div class="header-area">
            <img :src="addForm.head_img" v-if="addForm.head_layout === 1"/>
            <div v-html="addForm.head_txt" v-if="addForm.head_layout === 2" :style="'color:' + addForm.head_txt_color"></div>
          </div>
          <div class="copyright" :style="'color:' + addForm.copyright_color">{{addForm.copyright}}</div>
          <div class="music" @click="musicplay" :class="{'rotating' : musicData.isrotating}" :style="'opacity:' + this.musicData.opacity">
            <div class="bgm-btn rotate" style="animation-play-state: paused;" ref="bgmBtn">
              <audio :src="addForm.bg_music" loop autoplay ref="audio" controls="controls" preload hidden></audio>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-steps :active="activeStep" align-center finish-status="success">
          <el-step title="基础信息"></el-step>
          <el-step title="页面设置"></el-step>
          <el-step title="微信分享"></el-step>
        </el-steps>
        <el-scrollbar height="400px">
          <el-form label-position="top" :model="addForm" :rules="addRules" ref="addRef" label-width="auto" class="add-form">
            <div class="step-content" v-show="activeStep === 0">
              <div class="form-content">
                <el-form-item label="会议标题" prop="title">
                  <el-input v-model="addForm.title"></el-input>
                </el-form-item>
                <el-form-item label="会议开始时间" prop="start_time">
                  <el-date-picker v-model="addForm.start_time" :default-value="Date.parse(new Date())" type="datetime" placeholder="选择日期时间" value-format="timestamp" @change="addStartTime"></el-date-picker>
                  <el-checkbox v-model="addForm.start_time" :true-label="''" :false-label="Date.parse(new Date())">无限制</el-checkbox>
                </el-form-item>
                <el-form-item label="会议结束时间" prop="end_time">
                  <el-date-picker v-model="addForm.end_time" type="datetime" :default-value="Date.parse(new Date())" placeholder="选择日期时间" value-format="timestamp" @change="addEndTime"></el-date-picker>
                  <el-checkbox v-model="addForm.end_time" :true-label="''" :false-label="Date.parse(new Date())">无限制</el-checkbox>
                </el-form-item>
                <el-form-item label="版权信息" prop="copyright">
                  <el-input v-model="addForm.copyright"></el-input>
                </el-form-item>
                <el-form-item label="是否需要注册浏览" prop="is_register">
                  <el-radio-group v-model="addForm.is_register">
                    <el-radio :label="1">需要</el-radio>
                    <el-radio :label="2">不需要</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="注册是否自动审核" prop="auto_check">
                  <el-radio-group v-model="addForm.auto_check">
                    <el-radio :label="1">自动</el-radio>
                    <el-radio :label="2">手动</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="门票是否自动审核" prop="auto_ticket">
                  <el-radio-group v-model="addForm.auto_ticket">
                    <el-radio :label="1">自动</el-radio>
                    <el-radio :label="2">手动</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeStep = 1" type="primary">下一步</el-button>
              </el-form-item>
            </div>
            <div class="step-content" v-show="activeStep === 1">
              <div class="form-content">
                <el-form-item label="背景图片" prop="bg_img">
                  <upload :url="addForm.bg_img" :filed="'bg_img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
                  <div class="tips">
                    您也可以
                    <span class="point-text" @click="bgVisible = true">点击此处</span>
                    ，快速选择背景模板
                  </div>
                </el-form-item>
                <el-form-item label="顶部排版类型" prop="head_layout">
                  <el-radio-group v-model="addForm.head_layout">
                    <el-radio :label="1">图片</el-radio>
                    <el-radio :label="2">文字</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="顶部文字" prop="head_txt">
                  <el-input v-model="addForm.head_txt"></el-input>
                </el-form-item>
                <el-form-item label="顶部图片" prop="head_img">
                  <upload :url="addForm.head_img" :filed="'head_img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
                </el-form-item>
                <el-form-item label="顶部文字颜色" prop="head_txt_color">
                  <el-color-picker v-model="addForm.head_txt_color" show-alpha></el-color-picker>
                </el-form-item>
                <el-form-item label="导航文字颜色" prop="nav_color">
                  <el-color-picker v-model="addForm.nav_color" show-alpha></el-color-picker>
                </el-form-item>
                <el-form-item label="版权文字颜色" prop="copyright_color">
                  <el-color-picker v-model="addForm.copyright_color" show-alpha></el-color-picker>
                </el-form-item>
                <el-form-item label="背景音乐" prop="bg_music">
                  <upload :url="addForm.bg_music" :filed="'bg_music'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeStep = 0" type="warning">上一步</el-button>
                <el-button @click="activeStep = 2" type="primary">下一步</el-button>
              </el-form-item>
            </div>
            <div class="step-content" v-show="activeStep === 2">
              <div class="form-content">
                <el-form-item label="微信分享图标" prop="wx_img">
                  <upload :url="addForm.wx_img" :filed="'wx_img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
                </el-form-item>
                <el-form-item label="微信分享标题" prop="wx_title">
                  <el-input v-model="addForm.wx_title"></el-input>
                </el-form-item>
                <el-form-item label="微信分享内容" prop="wx_content">
                  <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入内容" v-model="addForm.wx_content"></el-input>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeStep = 1" type="warning">上一步</el-button>
                <el-button @click="submitForm" type="success">提 交</el-button>
              </el-form-item>
            </div>
        </el-form>
        </el-scrollbar>
      </div>
    </div>
    <!-- 公共背景模板选择弹窗 -->
    <el-dialog title="公共背景" :visible.sync="bgVisible" width="50%">
      <div class="bg-list">
        <div class="bg-item" v-for="item in bgList" :key="item.id" @click="selectBgItem(item.url)">
          <el-image :src="item.url" lazy></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bgVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../../common/Upload'
export default {
  name: 'Add',
  components: {
    Upload
  },
  data () {
    return {
      activeStep: 0,
      musicData: {
        isrotating: false,
        opacity: 0.4
      },
      addForm: {
        title: '',
        start_time: '',
        end_time: '',
        copyright: '',
        is_register: 2,
        auto_check: 1,
        auto_ticket: 1,
        bg_img: '',
        head_layout: 1,
        head_txt: '',
        head_img: '',
        head_txt_color: 'rgba(0,0,0,1)',
        nav_color: 'rgba(0,0,0,1)',
        copyright_color: 'rgba(0,0,0,1)',
        bg_music: '',
        wx_img: '',
        wx_title: '',
        wx_content: ''
      },
      addRules: {
        title: [
          { required: true, message: '请填写会议标题名称', trigger: 'blur' },
          { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
        ],
        bg_img: [
          { required: true, message: '请上传背景图片', trigger: 'blur' }
        ],
        wx_img: [
          { required: true, message: '请上传微信分享图标', trigger: 'blur' }
        ],
        wx_title: [
          { required: true, message: '请填写微信分享标题', trigger: 'blur' }
        ],
        wx_content: [
          { required: true, message: '请填写微信分享内容', trigger: 'blur' }
        ]
      },
      // 公共背景模板弹窗
      bgVisible: false,
      // 公共背景列表
      bgList: []
    }
  },
  created () {
    console.log(Date.parse(new Date()))
    // 获取公共背景模板
    this.getBgList()
  },
  methods: {
    // 提交表单
    submitForm () {
      this.$refs.addRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/shell-meeting/0', this.addForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.addRef.resetFields()
            await this.$router.push('/admin/apps/shell/meeting-list')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    addStartTime (val) {
      this.addForm.start_time = val
    },
    addEndTime (val) {
      this.addForm.end_time = val
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    },
    musicplay () {
      if (this.$refs.audio.paused && this.addForm.bg_music) {
        this.musicData.isrotating = true
        this.musicData.opacity = 1
        window.sessionStorage.setItem('music', 'true')
        this.$refs.audio.play()
      } else {
        this.musicData.isrotating = false
        this.musicData.opacity = 0.4
        window.sessionStorage.setItem('music', 'false')
        this.$refs.audio.pause()
      }
    },
    // 获取公共背景模板
    async getBgList () {
      const { data: res } = await this.$http.get('/shell-bg-list')
      if (res.status === 200) {
        this.bgList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 选中某一个背景
    selectBgItem (url) {
      this.addForm.bg_img = url
      this.bgVisible = false
    }
  }
}
</script>

<style scoped>
.page-content{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.page-content .left{
  width: 320px;
}
.page-content .right{
  flex: 1;
  max-width: 420px;
}
.step-content{
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255,255,255,0.5);
  position: relative;
  margin-top: 20px;
}
.step-content .form-content{
  height: calc(100vh - 471px);
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.step-content .form-content::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #d3dce6;
}

/*定义滚动条轨道 内阴影+圆角*/
.step-content .form-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
.step-content .form-content::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  background-color: rgb(19, 206, 102);
}
.step-content .step-btn-area{
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255,255,255,1);
  text-align: center;
  padding: 14px;
  margin-bottom: 0;
}
/deep/.el-step__title{
  font-size: 13px;
}
.page-content .left .preview-panel{
  background-color: rgba(0,0,0,0.1);
  height: calc(100vh - 324px);
  position: relative;
  background-size: cover;
}
.page-content .left .preview-panel .header-area{}
.page-content .left .preview-panel .header-area img{
  width: 100%;
}
.page-content .left .preview-panel .header-area div{
  padding: 20px;
  word-break: break-all;
}
.page-content .left .preview-panel .copyright{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  word-break: break-all;
}
.page-content .left .preview-panel .music{
  position: absolute;
  top: 14px;
  right: 14px;
  width: 40px;
  height: 40px;
}
.page-content .left .preview-panel .music img{
  width: 100%;
  height: 100%;
}
.music{
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 200;
  border-radius: 15px;
}
.rotating{
  -webkit-animation: rotating 1.2s linear infinite;
  animation: rotating 1.2s linear infinite;
}
.music .bgm-btn{
  width: 100%;
  height: 100%;
  background-image: url('../../../../assets/img/shell/music.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 15px;
}
@keyframes rotating {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}
.el-form-item .tips{
  font-size: 13px;
  color: #909399;
}
.el-form-item .tips .point-text{
  color: #F56C6C;
  cursor: pointer;
}
.bg-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 520px;
  overflow-y: auto;
}
.bg-list .bg-item{
  width: 120px;
  height: auto;
  margin: 20px;
  cursor: pointer;
}
.bg-item:hover .el-image{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
</style>
